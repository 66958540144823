// Gatsby requirements
import React, { useState } from "react"

// Components
import RichText from "./RichTextRenderer"

// Styles
import * as CSS from './SectionContactUs.module.scss'


const SectionContactUs = ({ data }) => {

    const [state, setState] = React.useState({})

    const [formSubmitted, setFormSubmitted] = useState(false)

    const handleChange = (e) => {
      setState({ ...state, [e.target.name]: e.target.value })
    }

    function encode(data) {
        return Object.keys(data)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
          .join('&')
    }
  
    const handleSubmit = (e) => {
      e.preventDefault()
      const form = e.target
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      })
        .then(() => setFormSubmitted(true))
        .catch((error) => alert(error))
    }

    return (
        <section className={CSS.section}>

            {data?.richText && 
                <RichText content={data.richText} />
            }

            <form
                name="Contact Form"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                className={CSS.form}
            >

                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                    </label>
                </p>

                <div className={CSS.inputs}>
                    <div className={CSS.flex}>
                        <label>
                            <input onChange={handleChange} type="text" name="Name" required />
                            <span>Your Name</span>
                        </label>
                
                        <label>
                            <input onChange={handleChange} type="text" name="Email" required />
                            <span>Email</span>
                        </label>
                                    
                        <label>
                            <input onChange={handleChange} type="phone" name="Phone" required />
                            <span>Phone</span>
                        </label>
                                    
                        <label>
                            <input onChange={handleChange} type="text" name="Company" required />
                            <span>Company</span>
                        </label>
                        
                        <label>
                            <input onChange={handleChange} type="text" name="Subject" required />
                            <span>Subject</span>
                        </label>
                        
                        <label>
                            <input onChange={handleChange} type="text" name="Location" required />
                            <span>Location</span>
                        </label>
                    </div>

                    <label>
                        <textarea onChange={handleChange} id="message" name="message" required />
                        <span>Message</span>
                    </label>
                </div>
                
                {/* <label className={CSS.checkbox}>
                    <input onChange={handleChange} type="checkbox" name="Agree to Terms and Conditions" required />
                    <span></span>
                    I agree to the <a target="_blank" href="/terms-and-conditions/" >Terms and Conditions</a>
                </label> */}

                <br/>

                <label className={CSS.checkbox}>
                    <input onChange={handleChange} type="checkbox" name="Agree to receiving information" />
                    <span></span>
                    I agree to receive information from Butterfly
                </label>

                <p id="form-success" style={{display: formSubmitted === true ? 'block' : 'none'}} className={CSS.success}>Form sent succesfully.</p>

                <br/>

                <button className="button" type="submit" name="submit">Send Message</button>

            </form>

        </section>
    )
}

export default SectionContactUs
