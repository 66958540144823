// Gatsby requirements
import React, { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

// Components
import RichText from "./RichTextRenderer"

// Styles
import * as CSS from './SectionCards.module.scss'


const SectionCards = ({ data }) => {


    // All of this enables the carousel and its navigation dots
    // I just copy pasted it from the docs: https://www.embla-carousel.com/api/
    const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
    const [selectedIndex, setSelectedIndex] = useState(0);
  
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
      embla
    ]);
  
    const onSelect = useCallback(() => {
      if (!embla) return;
      setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);
  
    useEffect(() => {
      if (!embla) return;
      onSelect();
      embla.on("select", onSelect);
    }, [embla, onSelect]);


    return (
        <section
            className={`
                ${CSS.section}
                ${data.style === 'Transparent background and checkmark' ? CSS.styleCheckmark : ''}
                ${data.style === 'Default but cards are connected' ? CSS.styleConnected : ''}
            `}
        >

            {data?.richText && 
                <RichText content={data.richText} />
            }

            {/* The regular cards section that gets shown on desktop */}
            <div className={`${CSS.cards} hidden-on-mobile`}>
                {data?.cards?.map((loop, i) => (
                    <div className={CSS.card} key={i}>
                        {loop?.richText && 
                            <RichText content={loop.richText} />
                        }
                    </div>
                ))}
            </div>

            {/* The carousel slider that gets shown on mobile */}
            <div className="embla hidden-on-desktop" ref={viewportRef}>
                <div className="embla__container">
                    {data?.cards?.map((loop, i) => (
                        <div className={`embla__slide ${CSS.mobileCard}`} key={i}>
                            {loop?.richText && 
                                <RichText content={loop.richText} />
                            }
                        </div>
                    ))}
                </div>
                <div className="embla__dots">
                    {data?.cards?.map((loop, i) => (
                        <button
                            key={i}
                            onClick={() => scrollTo(i)}
                            className={`embla__dot ${i === selectedIndex ? "embla__dot_selected" : ""}`}
                            aria-label={`Go to slide ${i + 1}`}
                        />
                    ))}
                </div>
            </div>

        </section>
    )
}

export default SectionCards
