// Gatsby requirements
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import useEmblaCarousel from 'embla-carousel-react'

// Components
import RichText from "./RichTextRenderer"

// Styles
import * as CSS from './SectionFeaturedArticles.module.scss'


const SectionFeaturedArticles = ({ data }) => {

    // All of this enables the carousel and its navigation dots
    // I just copy pasted it from the docs: https://www.embla-carousel.com/api/
    const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
    const [selectedIndex, setSelectedIndex] = useState(0);
  
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
      embla
    ]);
  
    const onSelect = useCallback(() => {
      if (!embla) return;
      setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);
  
    useEffect(() => {
      if (!embla) return;
      onSelect();
      embla.on("select", onSelect);
    }, [embla, onSelect]);


    return (
        <section className={`
            ${CSS.section}
            ${data?.style === 'Thumbnail + Title + Excerpt' ? CSS.thumbnailTitleExcerpt : ''}
            ${data?.style === 'Title + Excerpt' ? CSS.titleExcerpt : ''}
        `}>

            {data?.richText && 
                <RichText content={data.richText} />
            }

            {/* The regular articles section that gets shown on desktop */}
            <div className={`${CSS.articles} hidden-on-mobile`}>
                {data?.readMoreButton?.buttonText &&
                    <>
                        {data.readMoreButton?.linkInternal?.__typename === 'ContentfulPage' &&
                            <Link className={CSS.readMore} to={`/${data?.readMoreButton.linkInternal.url === 'home' ? '' : data?.readMoreButton.linkInternal.url + '/'}`}>
                                {data.readMoreButton.buttonText}
                            </Link>
                        }
                        {data.readMoreButton?.linkInternal?.__typename === 'ContentfulArticle' &&
                            <Link className={CSS.readMore} to={`/articles/${data?.readMoreButton.linkInternal.url}/`}>
                                {data.readMoreButton.buttonText}
                            </Link>
                        }
                    </>
                }
                {data?.articles?.map((loop, i) => (
                    <article className={CSS.article} key={i}>
                        {loop?.thumbnailImage?.gatsbyImageData && data.style !== 'Title + Excerpt' &&
                            <div className={CSS.thumbnail}>
                                <GatsbyImage
                                    loading="lazy"
                                    image={loop.thumbnailImage.gatsbyImageData}
                                    alt={loop.thumbnailImage.description}
                                />
                            </div>
                        }
                        {loop?.articleTitle && loop?.url &&
                            <Link to={`/articles/${loop.url}/`}>
                                <h3>{loop.articleTitle}</h3>
                            </Link>
                        }
                        {data?.style === 'Title + Excerpt' || data?.style === 'Thumbnail + Title + Excerpt' ?
                            <>{loop?.metaDescription?.metaDescription && <p>{loop.metaDescription.metaDescription}</p>}</>
                        : ''}
                    </article>
                ))}
            </div>

            {/* The carousel slider that gets shown on mobile */}
            {data?.readMoreButton?.buttonText &&
                <>
                    {data.readMoreButton?.linkInternal?.__typename === 'ContentfulPage' &&
                        <Link className={`hidden-on-desktop ${CSS.mobileReadMore}`} to={`/${data?.readMoreButton.linkInternal.url === 'home' ? '' : data?.readMoreButton.linkInternal.url + '/'}`}>
                            {data.readMoreButton.buttonText}
                        </Link>
                    }
                    {data.readMoreButton?.linkInternal?.__typename === 'ContentfulArticle' &&
                        <Link className={`hidden-on-desktop ${CSS.mobileReadMore}`} to={`/articles/${data?.readMoreButton.linkInternal.url}/`}>
                            {data.readMoreButton.buttonText}
                        </Link>
                    }
                </>
            }
            <div className="embla hidden-on-desktop" ref={viewportRef}>
                <div className="embla__container">
                    {data?.articles?.map((loop, i) => (
                        <article className="embla__slide" key={i}>
                            {loop?.thumbnailImage?.gatsbyImageData && data.style !== 'Title + Excerpt' &&
                                <div className={CSS.thumbnail}>
                                    <GatsbyImage
                                        loading="lazy"
                                        image={loop.thumbnailImage.gatsbyImageData}
                                        alt={loop.thumbnailImage.description}
                                    />
                                </div>
                            }
                            {loop?.articleTitle && loop?.url &&
                                <Link to={`/articles/${loop.url}/`}>
                                    <h3>{loop.articleTitle}</h3>
                                </Link>
                            }
                            {data?.style === 'Title + Excerpt' || data?.style === 'Thumbnail + Title + Excerpt' ?
                                <>{loop?.metaDescription?.metaDescription && <p>{loop.metaDescription.metaDescription}</p>}</>
                            : ''}
                        </article>
                    ))}
                </div>
                <div className="embla__dots">
                    {data?.articles?.map((loop, i) => (
                        <button
                            key={i}
                            onClick={() => scrollTo(i)}
                            className={`embla__dot ${i === selectedIndex ? "embla__dot_selected" : ""}`}
                            aria-label={`Go to slide ${i + 1}`}
                        />
                    ))}
                </div>
            </div>

        </section>
    )
}

export default SectionFeaturedArticles
