// Gatsby requirements
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

// Components
import RichText from './RichTextRenderer'
// import Share from './Share'

// Styles
import * as CSS from './Hero.module.scss'


const Hero = ({ title, image, url, date, timeToRead }) => {

    return (
        <div className={CSS.section}>

            <div className={CSS.wrapper}>
                <RichText content={title}/>
                <div className={CSS.shareAndDate}>
                    {/* <Share url={url}/> */}
                    {date &&
                        <p className={CSS.date}>{date}</p>
                    }
                    {timeToRead &&
                        <span className={CSS.timeToRead}>{timeToRead} min read</span>
                    }
                </div>
            </div>

            {image &&
                <GatsbyImage
                    loading="eager"
                    className={CSS.backgroundImage}
                    image={image.gatsbyImageData}
                    alt={image.description}
                />
            }

        </div>
    )
}

export default Hero
